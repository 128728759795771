import { BaseElement, html, css } from 'Elements';
import App from 'App';

class SoundPlayer extends BaseElement {

  static get styles() {
    return [
      css`
        a-route {
          color:var(--sl-color-primary-500);
        }
      `
    ]
  }

  constructor() {
    super();
    this.playSound = this.playSound.bind(this);

    this.audioInstances = {
      notif: new Audio('assets/sounds/notif.wav'),
      error: new Audio('assets/sounds/error.mp3')
    };

    // Écouter l'événement personnalisé 'playsound'
    window.addEventListener('playsound', this.playSound);
  }

  async playSound(ev) {
    const enabled = localStorage.getItem(App.config.localKeys.sound) || false;
    if (enabled) {
      const soundName = ev.detail || 'notif';
      if (this.audioInstances[soundName]) {
        this.audioInstances[soundName].currentTime = 0;
        try {
          await this.audioInstances[soundName].play();
        } catch(e) {
          if (e.message.match(/interact/)) {
            this.qs('modal-dialog').show();
            return;
          }
          console.error(e);
        }
      } else {
        console.warn(`Son "${soundName}" non trouvé.`);
      }
    }
  }

  render() {
    return html`
      <modal-dialog>
        Un son veut être joué, mais une interaction entre vous et le navigateur est nécessaire.
        <br/><br/>
        Rappel: le son est actuellement activé. Vous pouvez activer / desactiver le son dans<br/>
        Mon compte > <a-route href="/private/user/account#sounds">Alertes sonores</a-route>.
        <br/><br/>
        <sl-button slot="bt2" close="true" variant="primary">... entendu !</sl-button>
      </modal-dialog>
    `;
  }
}


customElements.define('sound-player', SoundPlayer);
