import { BaseElement, html, css } from 'Elements';
import { Sleep, Lang, Fetcher } from 'Utils';

class SelectSearch extends BaseElement {

  static get styles() {
    return [
      css`
        :host {
          display:block;
          width:100%;
        }

        sl-dropdown {
          width:100%;
        }

        sl-dropdown::part(panel) {
          background-color:var(--sl-panel-background-color);
        }

        sl-dropdown::part(popup) {
          margin-top:-5px;
        }

        sl-button {
          width:100%;
        }

        sl-button::part(base) {
          justify-content:flex-start;
          font-size:0.9em;
          line-height:initial;
        }

        sl-button::part(caret) {
          margin-left:auto;
        }

        sl-button::part(label) {
          width:100%;
          padding-left:0;
          padding-right:4px;
        }

        .spinner_bar {
          position: absolute;
          top: 14px;
          right: 5px;
          left: 5px;
        }

        .input_content {
          display: flex;
          justify-content: space-between;
          width: 100%;
        }

        .input_tags {
          display:flex;
          flex-wrap:wrap;
          gap:2px;
          margin-top:2px;
          margin-left:2px;
          width:100%;
          overflow:hidden;
        }

        .input {
          padding:2px;

        }

        .input_buttons {
          margin-top:4px;
          font-size:1.4em;
          display:flex;
          gap:2px;
        }

        .input_buttons m-icon {
          cursor:pointer;
          color:var(--sl-color-gray-400);
        }

        .input_buttons m-icon:hover {
          color:var(--sl-color-gray-700) !important;

        }

        sl-input {
        }

        sl-menu {
          width: 100%;
          min-width: 100%; /* Assure la largeur minimale */
          max-width: 100%;
          max-height:50vh;
          height:40vh;
        }

        sl-menu::part(base) {
          width: 100%;
        }

        sl-menu-item {
          width:100%;
        }

        sl-menu-item::part(base) {
          font-size:0.8em;
          line-height:1rem;
        }

        .placeholder {
          color: var(--sl-color-gray-400);
          font-size: 0.9em;
          line-height: 1.6rem;
          padding-left: 5px;
        }

        lit-virtualizer {
          min-height:100% !important;
          margin:0px;
        }

      `
    ]
  }

  static get properties() {
    return {
      value: { type: String },
      separator: { type: String },
      maxOptionsVisible: { type: Number, attribute: 'max-options-visible' },
      multiple: { type: Boolean },
      clearable: { type: Boolean },
      placeholder: { type: String },
      name: { type: String },
      items: { type: Array },
      virtualize: { type: Boolean },
      api: { type: String },
      primaryKey: { type: String, attribute: 'primary-key' },
      displayKey: { type: String, attribute: 'display-key' },
      renderItems: { type: Function },
    };
  }

  constructor() {
    super();
    this.debug = false;
    this.value = null;
    this.separator = ' ';
    this.maxOptionsVisible = 5;
    this.placeholder = 'Choisir un client';
    this.firstTime = true;
    this.multiple = false;
    this.dropdownVisible = false;
    this.selected = [];  
    this.itemsById = {};
    this.displayItems = [];
    this.primaryKey = 'id';
    this.displayKey = 'name';
    this.api = '';
    this.apiLoading = false;
    this.renderItem = this.renderItem.bind(this);

    if (!this.virtualize) {
      const data = {};
      this.items = Array.from(this.querySelectorAll('sl-option')).map(option => {
        data[this.primaryKey] = option.getAttribute('value');
        data[this.displayKey] = option.textContent;
        return {...data}
      });

      this.items.map(item => this.itemsById[this.getKey(item)] = item);
      this.displayItems = this.items;
    }
  }

  getKey(item) {
    return Lang.lookup(item, this.primaryKey);
  }

  getLabel(item) {
    return Lang.lookup(item, this.displayKey);
  }

  async updated(changedProperties) {
    if (changedProperties.has('value') && this.firstTime) {
      this.selected = this.value.split(this.separator).map(item => item);
      
      if (this.virtualize) {
        this.items.map(item => this.itemsById[this.getKey(item)] = item);
        this.displayItems = this.items;
      }

      this.computeSelected();
      
      this.firstTime = false;
      this._log.debug('updated', this.value, this.selected);
      this.requestUpdate();
    }

    if (changedProperties.has('api')) {
      if (this.api) this.apiLoading = true;
    }
  }

  computeSelected() {
    this.selected = this.value.split(this.separator).map(item => item);

    // remove empty values, duplicates, and values that are not in the list
    this.selected = this.selected.filter((item, index, self) => item && self.indexOf(item) === index && this.itemsById[item]);
  }

  async firstUpdated() {
    // get the width of the sl-dropdown
    let width = 0;
    while (!width) {
      width = this.qs('sl-dropdown').offsetWidth;
      await Sleep(5);
    }

    // set the width of the sl-menu
    this.qs('sl-menu').style.width = width+'px';
    await this.loadData();
  }

  async loadData() {
    if (!this.api) return;
    this.apiLoading = true;
    await Sleep(2000);
    const response = await Fetcher.get(this.api);
    this.apiLoading = false;
    if (!response) return;
    this.items = response.data;
    this.items.map(item => this.itemsById[this.getKey(item)] = item);
    this.displayItems = this.items;
    this.computeSelected();
    this.requestUpdate();
  }

  async onChange(ev) {
    ev.stopPropagation();
    ev.preventDefault();
    const item = ev.detail.item;

    this._log.debug('onChange', item.value)

    if (this.multiple) {
      item.selected = !item.selected;
      if (this.selected.includes(item.value)) {
        // already selected, remove it
        this._log.debug('remove', item.value);
        this.selected = this.selected.filter(id => id.toString() !== item.value.toString());
      } else {
        // add it
        this._log.debug('add', item.value);
        this.selected.push(item.value);
      }
      this.value = this.selected.join(this.separator);
      this._log.debug('new value is', this.value);
    } else {
      this.selected = [item.value];
      this.value = item.value;
    }
    this.emitChange();
  }

  async onInput(ev) {
    this.displayItems = this.items.filter(item => this.getLabel(item).toLowerCase().includes(ev.target.value.toLowerCase()));
    if (this.virtualize) {
      this.qs('lit-virtualizer').items = this.displayItems;
    } else {
      this.requestUpdate();
    }
  }

  async onSearchClear(ev) {
    this.displayItems = this.items;
    this.requestUpdate();
  }

  async onDropDownShow(ev) {
    this._log.debug('onDropDownShow');
    await Sleep(10);
    this.dropdownVisible = true;
    this.qs('sl-input').focus();
    this.renderVirtualizer();
  }

  async onDropDownHide(ev) {
    this._log.debug('onDropDownHide');
    this.dropdownVisible = false;
    // avoid mem leak !
    if (this.qs('lit-virtualizer')) {
      this.qs('lit-virtualizer').items = [];
    }
  }

  async onInputClear(ev) {
    ev.stopPropagation();
    ev.preventDefault();
    this.value = null;
    this.selected = [];
    this.emitChange();
  }

  async onRemoveTag(ev) {
    this._log.debug(`onRemoveTag: dropdownVisible=${this.dropdownVisible}`);
    if (this.dropdownVisible) {
      ev.stopPropagation();
      ev.preventDefault();

      this.selected = this.selected.filter(id => id !== ev.target.getAttribute('data-id'));
      this.value = this.selected.join(this.separator);
      this.emitChange();
      this.requestUpdate();
    }
  }

  emitChange() {
    this._log.debug('emitChange', this.value);
    this.dispatchEvent(new CustomEvent('sl-change', { detail: { value: this.value } }));
  }

  renderSelected() {
    this._log.debug('renderSelected', this.selected);
    if (this.apiLoading) {
      return '';
    }

    if (!this.selected.length) {
      return html`<div class="placeholder">${this.placeholder}</div>`;
    }

    if (this.multiple) {
      if (this.selected.length > this.maxOptionsVisible) {
        return this.selected.slice(0, this.maxOptionsVisible).map(id => {
          return html`<sl-tag type="primary" data-id=${id} removable size="small" @sl-remove=${this.onRemoveTag}>${this.getLabel(this.itemsById[id])}</sl-tag>`;
        }).concat(html`<sl-tag type="primary" size="small">+${this.selected.length - this.maxOptionsVisible}</sl-tag>`);
      }

      return this.selected.map(id => {
        return html`<sl-tag type="primary" data-id=${id} removable size="small" @sl-remove=${this.onRemoveTag}>${this.getLabel(this.itemsById[id])}</sl-tag>`;
      });
    } else {
      const id = this.selected[0];
      return html`<sl-tag type="primary" size="small">${this.getLabel(this.itemsById[id])}</sl-tag>`;
    }
  }

  renderItem(item) {
    return this.renderSlMenuItem(item);
  }

  renderVirtualizer() {
    if (!this.virtualize) {
      return;
    }
    const vt = this.qs('lit-virtualizer');
    vt.items = this.items;
    if (this.renderItems) {
      this.renderItems = this.renderItems.bind(this);
      vt.renderItem = this.renderItems;
    } else {
      vt.renderItem = this.renderItem;
    }
    vt.requestUpdate();
  }

  isSelected(item) {
    return this.selected.includes(this.getKey(item));
  }

  renderSlMenuItem(item) {
    return html`<sl-menu-item type="checkbox" ?checked=${this.isSelected(item)} value="${this.getKey(item)}">${this.getLabel(item)}</sl-menu-item>`;
  }

  renderSpinner() {
    return html`
      ${this.api
        ? this.apiLoading 
          ? html`<div class="spinner_bar"><sl-progress-bar style="--height: 2px;" indeterminate></sl-progress-bar></div>`
          : ''
        : ''
      }`
  }

  renderInputIcons() {
    if (this.apiLoading) {
      return '';
    }

    return html`
      <div slot="suffix" class="input_buttons">
        ${this.selected.length
          ? this.clearable
            ? html`<m-icon name="clear" @click=${this.onInputClear}></m-icon>`
            : ''
          : ''
        }
        <m-icon name="expand_circle_down"></m-icon>
      </div>`
  }

  render() {
    return html`
      <sl-dropdown hoist distance="0" @sl-show=${this.onDropDownShow} @sl-hide=${this.onDropDownHide}>
        <sl-button size="small" slot="trigger" ?disabled="${this.apiLoading}">
          <div class="input_content">
            <div class="input_tags">
              ${this.renderSpinner()}
              ${this.renderSelected()}
            </div>
            ${this.renderInputIcons()}
          </div>
        </sl-button>        

        <div class="input">
          <sl-input size="small" clearable @sl-input=${this.onInput} @sl-clear=${this.onSearchClear}>
            <m-icon name="search" slot="suffix"></m-icon>
          </sl-input>
        </div>

        <sl-menu @sl-select=${this.onChange}>
          ${this.virtualize
            ? html`<lit-virtualizer scroller></lit-virtualizer>`
            : this.displayItems.map(item => this.renderSlMenuItem(item))
          }
        </sl-menu>
      </sl-dropdown>
    `;
  }
}

customElements.define('select-search', SelectSearch);