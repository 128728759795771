import { BaseElement, html, css, highlight } from 'Elements';
import { Sleep, Lang, Fetcher } from 'Utils';

class SelectSearchTree extends BaseElement {

  static get styles() {
    return [
      css`
        :host {
          display:block;
          width:100%;
        }

        sl-dropdown {
          width:100%;
        }

        sl-dropdown::part(panel) {
          background-color:var(--sl-panel-background-color);
        }

        sl-dropdown::part(popup) {
          margin-top:-5px;
        }

        sl-button {
          width:100%;
        }

        sl-button::part(base) {
          justify-content:flex-start;
          font-size:0.9em;
          line-height:initial;
        }

        sl-button::part(caret) {
          margin-left:auto;
        }

        sl-button::part(label) {
          width:100%;
          padding-left:0;
          padding-right:4px;
        }

        .spinner_bar {
          position: absolute;
          top: 14px;
          right: 5px;
          left: 5px;
        }

        .input_content {
          display: flex;
          justify-content: space-between;
          width: 100%;
        }

        .input_tags {
          display:flex;
          flex-wrap:wrap;
          gap:2px;
          margin-top:2px;
          margin-left:2px;
          width:100%;
          overflow:hidden;
        }

        .input {
          padding:2px;
        }

        .input_buttons {
          margin-top:4px;
          font-size:1.6em;
          margin-right:4px;
          display:flex;
          gap:2px;
        }

        .input_buttons m-icon {
          cursor:pointer;
          color:var(--sl-color-gray-400);
        }

        .input_buttons m-icon:hover {
          color:var(--sl-color-gray-700) !important;
        }

        sl-tree {
          width: 100%;
          min-width: 100%; /* Assure la largeur minimale */
          max-width: 100%;
          max-height:50vh;
          height:40vh;
          overflow-y:scroll;
          --indent-guide-width: 1px;
        }

        .tree-item::part(label) {
          font-size:0.8rem;
        }

        .tree-item::part(expand-button) {
          padding:3px;
        }

        .tree-item.hidden {
          display:none;
        }

        .tree-item::part(children)::before {
          left: 14px;
        }

        .placeholder {
          color: var(--sl-color-gray-400);
          font-size: 0.9em;
          line-height: 1.6rem;
          padding-left: 5px;
        }

        .gray::part(label) {
          opacity:0.6;
        }

        mark {
          background-color: yellow;
        }

        sl-tag::part(base) {
          font-size:0.9em;
        }

      `
    ]
  }

  static get properties() {
    return {
      value: { type: String },
      separator: { type: String },
      maxOptionsVisible: { type: Number, attribute: 'max-options-visible' },
      selection: { type: String },
      multiple: { type: Boolean },
      clearable: { type: Boolean },
      parentShow: { type: Boolean, attribute: 'parent-show' },
      parentSeparator: { type: String, attribute: 'parent-separator' },
      parentInclude: { type: Boolean, attribute: 'parent-include' },
      placeholder: { type: String },
      name: { type: String },
      items: { type: Array },
      api: { type: String },
      primaryKey: { type: String, attribute: 'primary-key' },
      displayKey: { type: String, attribute: 'display-key' },
      parentKey: { type: String, attribute: 'parent-key' },
      itemsById: { type: Object },
      selected: { type: Array },
      apiLoading: { type: Boolean },
    };
  }

  constructor() {
    super();
    this.debug = false;
    this.value = '';
    this.selection = 'single';
    this.separator = ' ';
    this.parentShow = false;
    this.parentShowSeparator = ' > ';
    this.parentInclude = false;
    this.maxOptionsVisible = 5;
    this.placeholder = 'Choisir une option';
    this.multiple = false;
    this.clearable = false;
    this.api = '';
    this.apiLoading = false;
    this.items = [];
    this.itemsById = {};
    this.selected = [];
    this.primaryKey = 'id';
    this.displayKey = 'name';
    this.parentKey = 'parentId';
    this.treeData = [];
  }

  async firstUpdated() {
    // get the width of the sl-dropdown
    let width = 0;
    while (!width) {
      width = this.qs('sl-dropdown').offsetWidth;
      await Sleep(5);
    }

    // set the width of the sl-menu
    this.qs('sl-tree').style.width = width+'px';
    await this.loadData();
  }


  computeSelected() {
    this.selected = this.value.split(this.separator).map(item => item);

    // remove empty values, duplicates, and values that are not in the list
    this.selected = this.selected.filter((item, index, self) => item && self.indexOf(item) === index && this.itemsById[item]);
  }


  async loadData() {
    if (!this.api) return;
    this.apiLoading = true;
    await Sleep(200);
    const response = await Fetcher.get(this.api);
    this.apiLoading = false;
    if (!response) return;
    this.items = response.data;
    this.items.map(item => this.itemsById[this.getKey(item)] = item);
    this.computeSelected();
    this.buildTreeData();
  }

  buildTreeData() {
    // Préparer un mapping des items par ID
    this.itemsById = {};
    this.items.forEach(item => {
      this.itemsById[item[this.primaryKey]] = {
        ...item,
        hidden:false,
        children: []
      };
    });

    // Créer l'arborescence
    this.treeData = [];
    this.items.forEach(item => {
      const parentId = item[this.parentKey];
      if (parentId && this.itemsById[parentId]) {
        this.itemsById[parentId].children.push(this.itemsById[item[this.primaryKey]]);
      } else {
        this.treeData.push(this.itemsById[item[this.primaryKey]]);
      }
    });
  }

  async onDropDownShow(ev) {
    this._log.debug('onDropDownShow');
    await Sleep(10);
    this.dropdownVisible = true;
    this.qs('sl-input').focus();
  }

  async onDropDownHide(ev) {
    this._log.debug('onDropDownHide');
    this.dropdownVisible = false;
  }

  filterTree(items, filter) {
    let hasMatchingChild = false;

    for (const item of items) {
      const matchesFilter = Lang.lookup(item, this.displayKey).toLowerCase().includes(filter);
      const hasMatchingDescendants = this.filterTree(item.children || [], filter);

      // L'élément est masqué s'il ne correspond pas au filtre et n'a aucun descendant correspondant
      item.hidden = !(matchesFilter || hasMatchingDescendants);

      // Si l'élément ou l'un de ses descendants correspond, il n'est pas masqué
      if (!item.hidden) {
        hasMatchingChild = true;
      }
    }

    return hasMatchingChild;
  }


  renderTreeItems(items) {
    // Basic: you can override me
    return items
      .map(item => html`
        <sl-tree-item
          expanded
          class="tree-item ${item.hidden ? 'hidden' : ''}"
          ?selected=${this.isSelected(item)}
          value="${item[this.primaryKey]}"
          .item=${item}
        >
          <span class="title">${highlight(Lang.lookup(item, this.displayKey), this.q)}</span>
          ${item.children?.length ? this.renderTreeItems(item.children) : ''}
        </sl-tree-item>
      `);
  }

  onTreeChange(ev) {
    this.selected = ev.detail.selection.map(item => item.getAttribute('value') );
    this._log.debug('onTreeChange', this.value);
    this.requestUpdate();
    this.emitChange();
  }

  isSelected(item) {
    return this.selected.includes(item[this.primaryKey]);
  }

  emitChange() {
    if (this.selection === 'multiple') {
      this.value = this.selected[0];
    } else {
      let value = this.selected.join(this.separator);
      if (this.parentInclude) {        
        let parent = this.itemsById[value];
        while (parent && parent.parentId) {
          parent = this.itemsById[parent.parentId];
          value = parent[this.primaryKey]+','+value;
        }
      }
      this.value = value;
    }
    this.dispatchEvent(new CustomEvent('change', { detail: { value: this.value } }));
  }

  getKey(item) {
    return Lang.lookup(item, this.primaryKey);
  }

  getLabel(item) {
    return Lang.lookup(item, this.displayKey);
  }

  renderSelected() {
    this._log.debug('renderSelected', this.selected);
    if (this.apiLoading) {
      return '';
    }

    if (!this.selected.length) {
      return html`<div class="placeholder">${this.placeholder}</div>`;
    }

    if (this.selection === 'multiple') {
      if (this.selected.length > this.maxOptionsVisible) {
        return this.selected.slice(0, this.maxOptionsVisible).map(id => {
          return html`<sl-tag type="primary" data-id=${id} removable size="small" @sl-remove=${this.onRemoveTag}>${this.getLabel(this.itemsById[id])}</sl-tag>`;
        }).concat(html`<sl-tag type="primary" size="small">+${this.selected.length - this.maxOptionsVisible}</sl-tag>`);
      }

      return this.selected.map(id => {
        return html`<sl-tag type="primary" data-id=${id} removable size="small" @sl-remove=${this.onRemoveTag}>${this.getLabel(this.itemsById[id])}</sl-tag>`;
      });
    } else {
      const id = this.selected[0];
      let label = this.getLabel(this.itemsById[id]);
      if (this.parentShow) {
        const parentId = this.itemsById[id].parentId;
        if (parentId) {
          const parent = this.itemsById[parentId];
          label = `${this.getLabel(parent)} ${this.parentSeparator} ${label}`;
        }
      }
        
      return html`<sl-tag type="primary" size="small">${label}</sl-tag>`;
    }
  }

  onRemoveTag(id) {
    this.selected = this.selected.filter(itemId => itemId !== id);
    this.value = this.selected.join(this.separator);
    this.emitChange();
  }

  treeRestoreExpanded() {
    const items = this.tree.querySelectorAll('sl-tree-item');
    if (!items) return;

    items.forEach(treeItem => {
      treeItem.item.hidden = false;
      treeItem.classList.remove('hidden');
      treeItem.classList.remove('gray');
    });
  }

  async onInput(ev) {

    const target = ev.target;
    this.q = target.value.toLowerCase().trim();
    clearTimeout(this.searchTimeout);

    this.searchTimeout = setTimeout(() => {

      if (!this.q) {
        this.treeRestoreExpanded();
        return;
      }
      
      this.tree = this.tree || this.qs('sl-tree');
      if (!this.tree) return;

      const items = this.tree.querySelectorAll('sl-tree-item');
      if (!items) return;

      items.forEach(treeItem => {
        let found = false;
        
        //console.log('analyze', treeItem.item.title.fr);

        // loop trought all item attribut values to find a match
        for (let [key, value] of Object.entries(treeItem.item)) {
          if (key === '_id') continue;
          //console.log('==> look', q,'in', value);
          if (typeof value === 'object') {
            value = Lang.lookup(treeItem.item, this.displayKey);
          }
          if (value && value.toString().toLowerCase().indexOf(this.q) >= 0) {
            //console.log('==> found', q,'in', treeItem);
            found = true;
            break;
          }
        }

        if (found) {
          // visible
          treeItem.classList.remove('hidden');
          treeItem.classList.remove('gray');

          while (treeItem.parentElement) {
            treeItem = treeItem.parentElement;
            treeItem.expanded = true;
            treeItem.classList.remove('hidden');
            treeItem.classList.add('gray');
          }

          this.requestUpdate();
        } else {
          treeItem.classList.add('hidden');
        }

      });
    }, 500);
  }

  renderSpinner() {
    return html`
      ${this.api
        ? this.apiLoading 
          ? html`<div class="spinner_bar"><sl-progress-bar style="--height: 2px;" indeterminate></sl-progress-bar></div>`
          : ''
        : ''
      }`
  }

  async onInputClear(ev) {
    ev.stopPropagation();
    ev.preventDefault();
    this.value = null;
    this.selected = [];
    this.emitChange();
  }

  renderInputIcons() {
    if (this.apiLoading) {
      return '';
    }

    return html`
      <div slot="suffix" class="input_buttons">
        ${this.selected.length
          ? this.clearable
            ? html`<m-icon name="clear" @click=${this.onInputClear}></m-icon>`
            : ''
          : ''
        }
        <m-icon name="keyboard_arrow_down"></m-icon>
      </div>`
  }

  async onSearchClear(ev) {
    this.treeRestoreExpanded();
    this.requestUpdate();
  }

  render() {
    return html`
      <sl-dropdown hoist distance="0" @sl-show=${this.onDropDownShow} @sl-hide=${this.onDropDownHide}>
        <sl-button size="small" slot="trigger" ?disabled="${this.apiLoading}">
          <div class="input_content">
            <div class="input_tags">
              ${this.renderSpinner()}
              ${this.renderSelected()}
            </div>
            ${this.renderInputIcons()}
          </div>
        </sl-button>

        <div class="input">
          <sl-input size="small" clearable @sl-input=${this.onInput} @sl-clear=${this.onSearchClear}>
            <m-icon name="search" slot="suffix"></m-icon>
          </sl-input>
        </div>

        <sl-tree selection="${this.selection}" @sl-selection-change=${this.onTreeChange}>
          ${this.renderTreeItems(this.treeData)}
        </sl-tree>
      </sl-dropdown>
    `;
  }
}

customElements.define('select-search-tree', SelectSearchTree);
